import React from "react";
import * as Icon from "react-feather";
import FsLightbox from "fslightbox-react";

function Portfolio(props) {
  const {title, subtitle, imageUrl, githuburl, url} = props.content;

  return (
    <div className={props.isVisible ? "mi-portfolio mi-portfolio-visible" : "mi-portfolio"}>
      <div className="mi-portfolio-image">
        <img src={imageUrl} alt={title} />
        <ul>
          { githuburl ? <li>
            <a rel="noopener noreferrer" target="_blank" href={githuburl}>
              <Icon.Code/>
            </a>
          </li> : null}
          { url ? <li>
            <a rel="noopener noreferrer" target="_blank" href={url}>
              <Icon.Link/>
            </a>
          </li> : null}
        </ul>
      </div>
      {!url ? <h5>{title}</h5> : <h5>
        <a rel="noopener noreferrer" target="_blank" href={url}>
          {title}
        </a>
      </h5>}
      {subtitle ? <h6>{subtitle}</h6> : null}
      {!githuburl ? null : <FsLightbox
        sources={githuburl}
        />
      }
    </div>
  );
}

export default Portfolio;

import Mock from "../mock";

const database = {
  information: {
    name: 'James Maddox',
    aboutContent: "I'm a cloud engineer based in Louisville, KY. In my current role at Coalfire, I assist customers with meeting FedRAMP compliance in AWS, Google Cloud, and Azure by assisting in the deployment of tools in areas such as auditing and monitoring, vulnerability management, and configuration management. I also currently focus primarily on my team's Google Cloud initiatives for current and prospective Google Cloud clients. I have a passion for technology and a desire to learn whenever possible. Feel free to reach out and connect with me via Linkedin!",
    age: '',
    phone: '',
    nationality: '',
    language: '',
    email: '',
    address: '',
    freelanceStatus: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/james-maddox/',
      dribbble: '',
      github: 'https://github.com/jemadd04'
    },
    brandImage: '/images/headshot.jpg',
    aboutImage: '/images/hulksmash.jpg',
    aboutImageLg: '/images/hulksmash.jpg',
    cvfile: '/files/resume.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'color-pallet',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "HTML/CSS",
      value: 95,
    },
    {
      title: "JavaScript",
      value: 90
    },
    {
      title: "React",
      value: 70
    },
    {
      title: "C#/.Net",
      value: 85
    },
    {
      title: "AWS",
      value: 80
    },
    {
      title: "Python",
      value: 80
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "Black Lives Matter Gallery",
      subtitle: "A grid based gallery built with React, Unsplash API, and CSS Grid",
      imageUrl: "/images/blm-capture.PNG",
      githuburl: "https://github.com/jemadd04/blm-gallery",
      url: 'https://5edfb99179892505b6288f93--blm-photo-gallery.netlify.app/'
    },
    {
      id: 2,
      title: "RAREKicks",
      subtitle: "Online shoe store website built with HTML, CSS and vanilla JavaScript.",
      imageUrl: "/images/rarekicks.PNG",
      githuburl: "https://github.com/jemadd04/rarekicks",
      url: 'https://jemadd04.github.io/rarekicks/'
    },
    {
      id: 3,
      title: "Little Jimmy's Sushi",
      subtitle: "Responsive sushi restaurant website built with HTML, CSS, Bootstrap and vanilla JavaScript.",
      imageUrl: "/images/littlejimmys.PNG",
      githuburl: 'https://github.com/jemadd04/little-jimmys',
      url: 'https://jemadd04.github.io/little-jimmys/'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "April 2023 - Present",
        position: "Cloud Engineer",
        company: "Coalfire",
        details: "Consulting customers to ensure their applications meet FedRAMP compliance. Deploying tools from the three major cloud providers (AWS, Azure, GCP) as well as cloud-agnostic tools such as Splunk, Trend Micro, and Jira Service Management. Contributed to the foundational code base and documentation for prospective GCP clients. Using IaC tools (Terraform) to create modules for tool deployments and architecture setup."
      },
      {
        id: 2,
        year: "January 2022 - April 2023",
        position: "Associate Cloud Engineer",
        company: "Coalfire",
        details: ""
      },
      {
        id: 3,
        year: "September 2021 - January 2022",
        position: "Software Engineer",
        company: "American Airlines",
        details: "Created dynamic credit card payment modal in Angular for the company’s employee travel site."
      },
      {
        id: 4,
        year: "September 2020 - September 2021",
        position: "Junior Software Engineer",
        company: "RxLightning",
        details: "Built the new company website (www.rxlightning.com) from scratch using React and Material UI. Implemented a feature in the portal that allows customers to send and re-send a registration verification code via fax using C#/.Net on the backend and React/Material UI on the front end. Created the initial analytics dashboard and report for the company using Microsoft Power Bi. Utilized SQL queries to add enrollment form data to the database."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "June 2023",
        graduation: "Associate Cloud Engineer",
        university: "Google Cloud"
      },
      {
        id: 2,
        year: "January 2023",
        graduation: "Solutions Architect - Associate",
        university: "AWS"
      },
      {
        id: 3,
        year: "March 2022",
        graduation: "Terraform Associate Certification",
        university: "HashiCorp"
      },
      {
        id: 4,
        year: "September 2020",
        graduation: "Azure Fundamentals AZ-900",
        university: "Microsoft"
      },
      {
        id: 5,
        year: "May 2020",
        graduation: "Cloud DevOps Engineer",
        university: "Udacity",
        details: "The Cloud DevOps Engineer Nanodegree program allowed me to learn development from a cloud devops perspective. Skills learned and used include AWS, Docker, Kubernetes, Jenkins, CI/CD, blue/green deployment, IaC, and more."
      },
      {
        id: 6,
        year: "May 2018 - April 2019",
        graduation: "Front End Web Development | C#/.Net",
        university: "Code Louisville",
        details: "Completed two 12-week sessions focusing on front end web development and C#/.Net. Skills learned and used include HTML, CSS, JavaScript, C#, .Net, OOP Principles, and more."
      },
      {
        id: 7,
        year: "August 2014 - May 2018",
        graduation: "Bachelor of Science in Business Administration",
        university: "University of Louisville",
        details: "Completed bachelor's program at the University of Louisville School of Business. Majored in Business Administration with a concentration in Finance."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['502-974-8198'],
    emailAddress: ['james.maddox07@gmail.com'],
    address: "Louisville, KY USA"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
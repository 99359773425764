import React, { useState, useEffect } from "react";
import axios from 'axios';
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";

function About(){
  const [toggler, setToggler] = useState(false);
  const [information, setInformation] = useState("");
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);

  const handleToggler = (event) => {
    setToggler({
      toggler: event
    })
  }

  useEffect(() =>{
    axios.get('/api/information')
      .then(response =>{
        setInformation(response.data)
      })
    axios.get('/api/services')
      .then(response =>{
        setServices(response.data)
        console.log(services);
      })
    axios.get('/api/reviews')
      .then(response =>{
        setReviews(response.data)
        console.log(reviews);
      })
  }, [reviews, services])

  return (
    <Layout>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="About Me" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-about-image">
                <img
                  src={information.aboutImage}
                  alt="about"
                  onClick={() => handleToggler(!toggler)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mi-about-content">
                <p>
                Hi, I'm James! I'm a cloud engineer based in Louisville, KY. I have a passion for technology and a desire to learn whenever possible. Ever since I can remember, whether it was crafting custom WWE wrestler websites at the age of 10 or using HTML to change my MySpace page in high school, I've always had a passion for developing and searching for solutions. Feel free to reach out and connect via <a href="https://www.linkedin.com/in/james-maddox/">LinkedIn</a> or <a href="https://twitter.com/jamesmaddoxiv">Twitter</a>!
                </p>
                <a href={information.cvfile} className="mi-button" target="_blank" rel="noopener noreferrer">Download Resume</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
